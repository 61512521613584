.wrapper {
  display: flex;
  border: 1px solid black;
}

.cell {
  border-right: 1px solid black;
  padding: 4px;
  font-weight: 500;
}

.cell:last-child {
  border-right: none;
}

.area {
  flex: 2;
}

.material {
  flex: 3;
}

.dimensions {
  flex: 1;
  text-align: right;
}

.squareMetres {
  flex: 1;
  text-align: right;
}

.notes {
  flex: 2;
}
