.vertical {
  margin-top: 5px;
  margin-bottom: 5px;
}

.horizontal {
  display: flex;
  flex-direction: row;
  margin-top: 5px;
  margin-bottom: 5px;
}

.label {
  color: #888;
  font-weight: 300;
}

.content {
  color: #444;
  font-weight: 400;
}

.horizontal .label {
  padding-right: 10px;
}

.horizontal .content {
  flex: 1;
}