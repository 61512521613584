.wrapper {
  z-index: 9999999;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(255,255,255,0.8);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 80px;
  margin-bottom: 10px;
}

.text {
  font-size: 20px;
}