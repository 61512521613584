.table td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.clickable {
  cursor: pointer;
}

.numeric {
  text-align: right !important;
}

.rowDragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.rowDragging td {
  padding: 6px 16px 6px 16px !important;
  visibility: hidden;
}

.rowDragging .dragVisible {
  visibility: visible;
}

.antInput {
  width: 100%;
}

.antInput :global(.ant-input),
.antInput :global(.ant-select-selection--single),
.antInput :global(.ant-select-selection--multiple) {
  border: none;
  box-shadow: none !important;
}

.antInput :global(.ant-input):hover,
.antInput :global(.ant-select-selection--single):hover,
.antInput :global(.ant-select-selection--multiple):hover {
  background-color: #FAFAFA;
}

.antInput :global(.ant-select-selection--multiple),
.antInput :global(.ant-select-selection__choice) {
  cursor: pointer !important;
}

.totals {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.total {
  width: 120px;
  padding: 16px;
  text-align: right;
  font-weight: 600;
}
