.wrapper {
  display: flex;
  border: 1px solid black;
  border-top: none;
}

.cell {
  border-right: 1px solid black;
  padding: 4px;
}

.cell:last-child {
  border-right: none;
}

.customArea {
  flex: 2;
}

.customMaterial {
  flex: 3;
}

.customDimensions {
  flex: 1;
}

.customSquareMetres {
  flex: 1;
}

.notes {
  flex: 2;
  position: relative;
  display: flex;
  align-items: flex-start;
}

.content {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.input {
  width: 100%;
  background: none;
  border: none;
  border-radius: none;
  outline: none;
  padding: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.number {
  text-align: right;
}

.actions {
  width: 24px;
  position: absolute;
  right: -24px;
  text-align: center;
  cursor: pointer;
  opacity: 0%;
}

.wrapper:hover .actions {
  opacity: 100%;
}
