.container {
  border: 1px solid #E8E8E8;
  border-radius: 4px;
  overflow: hidden;
}

.header {
  height: 65px;
  padding-left: 15px;
  padding-right: 15px;
  display: flex;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid #E8E8E8;
}

.title {
  font-size: 16px;
  font-weight: 600;
  margin-right: 20px;
  flex: 1;
}

.tools {
  display: flex;
}

.body {
  
}