.item {
  height: 40px;
  line-height: 40px;
  padding-left: 20px;
  padding-right: 20px;
  cursor: pointer;
  white-space: nowrap;
  font-size: 15px;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.item:hover {
  background-color: #E5E6E8;
}