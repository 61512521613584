.wrapper {
  flex: 1;
  height: 1px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.board {
  height: 100%;
  display: flex;
  flex-direction: row;
  border-left: 1px solid #CCC;
}

.spin {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(255, 255, 255, 0.6);
}

.dayColumn {
  flex: 1;
  height: 100%;
  border: 1px solid #CCC;
  border-left: none;
  overflow-y: scroll;
}

.dayLabel {
  padding: 5px;
  font-size: 12px;
  font-weight: 500;
}