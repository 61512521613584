.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  margin: 2px;
  margin-left: 4px;
  margin-right: 4px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  color: white;
  font-size: 10px;
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tagBlack { background-color: rgba(51, 51, 51, 1); }

.tagGreen { background-color: rgba(84, 187, 105, 1); }

.tagBlue { background-color: rgba(59, 156, 224, 1); }

.tagPurple { background-color: rgba(151, 112, 215, 1); }

.tagGold { background-color: rgba(255, 191, 0, 1); }

.tagOrange { background-color: rgba(234, 106, 45, 1); }

.tagRed { background-color: rgba(210, 77, 74, 1); }

.tagBrown { background-color: rgba(167, 84, 10, 1); }