.wrapper {
  padding: 30px;
}

.userAvatar {
  cursor: default;
  background-color: #1990FF !important;
}

.submitButton {
  margin-top: 15px;
}