.wrapper {
  display: flex;
  border: 1px solid black;
}

.cell {
  border-right: 1px solid black;
  padding: 4px;
  font-weight: 500;
}

.cell:last-child {
  border-right: none;
}

.material {
  flex: 3;
}

.supplier {
  flex: 2;
}

.quantity {
  flex: 1;
  text-align: right;
}

.dimensions {
  flex: 1;
  text-align: right;
}

.squareMetres {
  flex: 1;
  text-align: right;
}

.totalSquareMetres {
  flex: 1;
  text-align: right;
}

.cost {
  flex: 1;
  text-align: right;
}
