.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 18px;
  margin: 2px;
  padding-left: 4px;
  padding-right: 4px;
  border-radius: 3px;
  color: white;
  background-color: #FFBF00;
  font-size: 10px;
  font-weight: 500;
  cursor: pointer;
}
