.table tbody tr {
  cursor: pointer;
}

.rowDragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.rowDragging td {
  padding: 6px 16px 6px 16px !important;
  visibility: hidden;
}

.rowDragging .dragVisible {
  visibility: visible;
}
