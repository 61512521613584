.header {
  border-bottom: 1px solid #e8e8e8;
  min-height: 65px;
  padding-left: 20px;
  padding-right: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  color: rgba(0, 0, 0, 0.85);
  font-size: 18px;
  font-weight: 500;
  margin-right: 12px;
}

.left {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: auto;
}