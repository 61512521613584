.wrapper {
  width: 100%;
  height: 70px;
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  background-color: #fafafa;
  border-top: 1px solid #ddd;
  z-index: 1;
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.1);
}

.inner {
  width: 920px;
  height: 100%;
  display: flex;
  align-items: center;
}

.left {
  flex: 1;
  display: flex;
}

.right {
  display: flex;
}

.button {
  width: 95px;
}
