.wrapper {
  height: 267px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.icon {
  font-size: 90px;
  line-height: 90px;
  color: #CCC !important;
}

.title {
  font-size: 18px;
  font-weight: 500;
  margin-top: 10px;
}

.text {
  color: #999;
  margin-top: 5px;
}