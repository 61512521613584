.wrapper {
  max-height: 200px;
  border: 1px solid #E8E8E8;
  padding: 5px 0px 0px 10px;
  margin-bottom: 4px;
  overflow: auto;
}

.wrapper :global(.ant-checkbox-group-item) {
  width: 100%;
  padding: 5px 0px 5px;
}
