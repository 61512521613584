.form {
  max-width: 900px;
  margin: auto !important;
}

.form :global(.ant-form-item) { 
  margin-bottom: 5px !important;
}

.areas {
  max-height: 200px;
  border: 1px solid #d9d9d9;
  padding: 7px 0px 7px 10px;
  overflow: auto;
}

.areas :global(.ant-checkbox-group-item) {
  display: block;
  padding-bottom: 4px;
}

.areas :global(.ant-checkbox-group-item):last-child {
  padding-bottom: 0px;
}
