.form {
  max-width: 900px;
  margin: auto !important;
}

.form :global(.ant-form-item) { 
  margin-bottom: 5px !important;
}

.formWrapper {
  display: flex;
  width: 100%;
}

.left {
  width: 50%;
  border-right: 1px solid #e8e8e8;
}

.right {
  width: 50%;
  padding-left: 24px;
}
