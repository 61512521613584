.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.center {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.right {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.otherPeriodsWithResults {
  color: #999;
  font-size: 12px;
  cursor: pointer;
  transition: color 0.5s;
  outline: none;
}

.otherPeriodsWithResults:hover {
  color: #CCC;
}