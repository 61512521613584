.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footerButtons {
  display: flex;
  justify-content: flex-end;
  padding: 30px;
}
