.wrapper {
  display: flex;
}

.left {
  flex: 1;
}

.right {
  flex: 1;
}

.fitterInfo {
  margin-left: 132px;
  margin-bottom: 5px;
  color: #999;
  font-size: 12px;
}

.inspectionInfo {
  margin-left: 132px;
  margin-top: -5px; /* Antd date input has 5px margin bottom, so pushes this down */
  margin-bottom: 5px;
  color: #999;
  font-size: 12px;
}

.checkboxLabel {
  display: inline-block;
  margin-right: 100px;
  margin-top: 10px;
}