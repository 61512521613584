.wrapper {
  margin-bottom: 10px;
  border: 1px solid #DDD;
}

.onHoldReasonCheckWrapper {
  display: inline-block;
  width: 25px;
}

.onHoldReasonName {
  padding-right: 20px;
}

.cancelButton {
  color: #999;
}

.cancelButton:hover {
  color: #1990FF;
}