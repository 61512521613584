.upper {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.upper h1 {
  flex: 1;
}

.upper .buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.lower {
  margin-bottom: 10px;
}