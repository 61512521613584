.plotTypeWrapper {
  padding-bottom: 30px;
}

.plotTypeHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.subheader {
  color: rgba(0, 0, 0, 0.65);
}

.headerButtons {
  display: none;
}

.plotTypeHeader:hover .headerButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
}

.m2Column {
  background-color: #FF99CC !important;
}

.numeric {
  text-align: right !important;
}

.clickable {
  cursor: pointer;
}
