.wrapper {
  display: flex;
  align-items: center;
}

.title {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}
