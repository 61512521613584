.wrapper {
  display: flex;
}

.left {
  flex: 1;
  display: flex;
}

.right {
  display: flex;
}
