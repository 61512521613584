.wrapper {
  display: flex;
}

.left {
  flex: 1;
  display: flex;
  gap: 12px;
}

.right {
  display: flex;
}
