.title {
  flex: 1;
  font-size: 16px;
  font-weight: 500;
}

.notes {
  border: 1px solid black !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 4px !important;
}
