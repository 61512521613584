.section {
  border: 1px solid black;
  border-bottom: none;
}

.detail {
  display: flex;
  border-bottom: 1px solid black;
  padding: 4px;
}

.label {
  flex: 1;
  font-weight: 500;
}

.value {
  flex: 2;
  text-align: right;
}
