.wrapper {
  padding: 30px;
}

.info {
  padding: 5px 0px;
  display: flex;
}

.label {
  width: 250px;
  font-weight: 500;
}

.value {
  width: 300px;
}
