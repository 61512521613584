.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

.actions {
  display: flex;
  align-items: center;
}

.link {
  color: #1990FF;
  cursor: pointer;
  margin-right: 25px;
}

.link:last-child {
  margin-right: 0px;
}

.link:hover {
  opacity: 0.8;
}

.deleteLink {
  color: #F5222D;
}

.link i {
  margin-right: 5px;
}