.wrapper {
  display: flex;
  border: 1px solid white;
  border-top: none;
}

.cell {
  border-right: 1px solid white;
  padding: 4px;
}

.cell:last-child {
  border-right: none;
}

.col1 {
  flex: 2;
}

.col2 {
  flex: 3;
}

.col3 {
  flex: 1;
}

.total {
  flex: 1;
}

.col5 {
  flex: 2;
}

.content {
  width: 100%;
  height: 100%;
  font-weight: 500;
}

.number {
  text-align: right;
}
