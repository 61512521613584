.wrapper {
  position: relative;
  outline: none;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}

.button {
  height: 60px;
  line-height: 60px;
  padding-left: 15px;
  padding-right: 15px;
  color: white;
  font-weight: 500;
  font-size: 15px;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  position: relative;
}

.highlight {
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
  height: 3px;
  background-color: #1690FF;
}

.button:hover {
  color: rgba(255,255,255,0.8);
}

.button.opened {
  background-color: rgba(0,0,0,0.2);
}

.dropdown {
  position: absolute;
  top: 65px;
  z-index: 999;
}

.dropdown.left {
  left: 0px;
}

.dropdown.right {
  right: 0px;
}