.areaGroupWrapper {
  padding-bottom: 30px;
}

.areaGroupHeader {
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
}

.headerWrapper {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.header {
  font-size: 21px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}

.subheader {
  color: rgba(0, 0, 0, 0.65);
}

.headerButtons {
  display: none;
}

.areaGroupHeader:hover .headerButtons {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding-bottom: 10px;
}

.table td {
  padding-top: 6px !important;
  padding-bottom: 6px !important;
}

.numeric {
  text-align: right !important;
}

.clickable {
  cursor: pointer;
}

.totals {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.total {
  width: 150px;
  padding: 16px;
  text-align: right;
  font-weight: 600;
  margin-right: 600px;
}
