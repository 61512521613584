.wrapper {
  flex: 1;
  height: 1px;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  position: relative;
}

.table {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.groupHeader {
  text-align: center !important;
}

.numericColumn {
  text-align: right !important;
}

.boldColumn {
  font-weight: 600 !important;
}
