.heading {
  
}

.heading h1 {

}

.heading h2 {
  
}

.heading h3 {
  
}

.heading h4 {
  
}