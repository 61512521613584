.header {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.title {
  font-size: 18px;
  font-weight: 600;
  flex: 1;
}

.link {
  color: #1990FF;
  cursor: pointer;
}

.link:hover {
  text-decoration: underline;
}