.wrapper {
  display: flex;
  border: 1px solid black;
  border-top: none;
}

.cell {
  border-right: 1px solid black;
  padding: 4px;
}

.cell:last-child {
  border-right: none;
}

.title {
  flex: 2;
}

.cost {
  flex: 1;
}

.content {
  width: 100%;
  height: 100%;
}

.input {
  width: 100%;
  background: none;
  border: none;
  border-radius: none;
  outline: none;
  padding: 0px;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.number {
  text-align: right;
}
