.wrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 30px;
}

.left {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.right {
  width: 600px;
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.switchWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.switchLabel {
  margin-left: 10px;
  font-size: 13px;
  font-weight: 600;
}

.dropdownLink {
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.dropdownLink:hover {
  color: #1990FF;
}

.dropdownIcon {
  font-size: 28px !important;
  margin-right: 5px;
}

.dropdownText {
  font-size: 13px;
  font-weight: 600;
}

.tag {
  display: flex;
  align-items: center;
  height: 28px;
  padding-left: 10px;
  padding-right: 10px;
  margin-left: 10px;
  border-radius: 14px;
  font-size: 12px;
  font-weight: 500;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tagBlack { border: 1px solid #333333; color: #333333; }
.tagBlack:hover { background-color: rgba(51, 51, 51, 0.1); }
.tagBlack.tagActive { background-color: #333333; color: white; }

.tagGreen { border: 1px solid #54BB69; color: #54BB69; }
.tagGreen:hover { background-color: rgba(84, 187, 105, 0.1); }
.tagGreen.tagActive { background-color: #54BB69; color: white; }

.tagBlue { border: 1px solid #3B9CE0; color: #3B9CE0; }
.tagBlue:hover { background-color: rgba(59, 156, 224, 0.1); }
.tagBlue.tagActive { background-color: #3B9CE0; color: white; }

.tagPurple { border: 1px solid #9770D7; color: #9770D7; }
.tagPurple:hover { background-color: rgba(151, 112, 215, 0.1); }
.tagPurple.tagActive { background-color: #9770D7; color: white; }

.tagGold { border: 1px solid #FFBF00; color: #FFBF00; }
.tagGold:hover { background-color: rgba(255, 191, 0, 0.1); }
.tagGold.tagActive { background-color: #FFBF00; color: white; }

.tagOrange { border: 1px solid #EA6A2D; color: #EA6A2D; }
.tagOrange:hover { background-color: rgba(234, 106, 45, 0.1); }
.tagOrange.tagActive { background-color: #EA6A2D; color: white; }

.tagRed { border: 1px solid #D24D4A; color: #D24D4A; }
.tagRed:hover { background-color: rgba(210, 77, 74, 0.1); }
.tagRed.tagActive { background-color: #D24D4A; color: white; }

.tagBrown { border: 1px solid #AA560B; color: #AA560B; }
.tagBrown:hover { background-color: rgba(167, 84, 10, 0.1); }
.tagBrown.tagActive { background-color: #AA560B; color: white; }

.tagGrey { border: 1px solid #9e9e9e; color: #9e9e9e; }
.tagGrey:hover { background-color: rgba(158, 158, 158, 0.1); }
.tagGrey.tagActive { background-color: #9e9e9e; color: white; }
