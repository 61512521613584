.footer {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.remindButton {
  margin-right: auto;
  padding-left: 8px;
  padding-right: 8px;
}