.section {
  border: 1px solid black;
  border-bottom: none;
}

.detail {
  display: flex;
  border-bottom: 1px solid black;
  padding: 4px;
}

.label {
  flex: 1;
  font-weight: 500;
}

.value {
  flex: 2;
}

.antInput,
.antInput :global(.ant-input) {
  width: 100% !important;
  border: none !important;
  border-radius: 0 !important;
  box-shadow: none !important;
  padding: 0px !important;
}

.antInput :global(.ant-calendar-picker-input) {
  height: 20px !important;
}

.antInput :global(.ant-calendar-picker-icon) {
  opacity: 0 !important;
}
