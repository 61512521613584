.wrapper {
  display: flex;
  border: 1px solid black;
  border-bottom: none;
}

.cell {
  padding: 4px;
}

.area {
  flex: 2;
}

.net {
  flex: 1;
  text-decoration: line-through;
}

.gross {
  flex: 1;
}

.content {
  width: 100%;
  height: 100%;
}

.number {
  text-align: right;
}
